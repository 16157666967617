<template>
  <div class="live-call-monitoring">
    <el-row :gutter="20" class="header">
      <el-col :span="22" class="title">
        <div class="user-display-name">
          {{ __("Live Conversation Monitoring") }}
        </div>
      </el-col>
      <el-col :span="2" class="actions">
        <el-button
          icon="el-icon-close"
          type="text"
          data-test="close-button"
          @click="handleCloseLive"
        ></el-button>
      </el-col>
    </el-row>

    <!-- Display Error Message -->
    <el-alert
      v-if="error"
      type="error"
      :closable="false"
      :title="error"
      show-icon
      class="error-alert"
    ></el-alert>

    <!-- Caller Input Form with Info Icon -->
    <el-form
      ref="callflowForm"
      :model="{ ani }"
      :rules="rules"
      size="small"
      label-position="top"
      label-width="120px"
    >
      <el-form-item prop="ani" class="form-item">
        <div class="label-container">
          <span>Caller Number </span>
          <el-tooltip
            class="item"
            effect="dark"
            content="Enter the number of the caller."
            placement="top"
          >
            <i class="el-icon-info info-icon"></i>
          </el-tooltip>
        </div>
        <el-input
          v-model="ani"
          ref="ani"
          :placeholder="__('Enter Phone Number')"
          :disabled="isShown || isLoading"
          data-test="ani-input"
        ></el-input>
      </el-form-item>

      <!-- Monitor Call Button -->
      <el-form-item class="button-container">
        <el-button
          type="primary"
          ref="monitorCallButton"
          data-test="monitor-call-button"
          @click="toggleMonitoring"
          :loading="isLoading"
          :disabled="isLoading"
        >
          {{ isMonitoring ? __("End Monitoring") : __("Monitor Call") }}
        </el-button>
      </el-form-item>
    </el-form>

    <!-- Live Call Monitoring Section -->
    <section v-if="isShown" class="call-monitoring-section">
      <div
        class="body"
        style="overflow-y: hidden"
        v-loading="isTaskLoading"
        :element-loading-text="__('Awaiting Active Call...')"
      >
        <!-- Current Conversation -->
        <div v-if="currentSession">
          <h4>Current Conversation</h4>
          <el-checkbox
            id="node-log-checkbox"
            v-model="isNodeLogsEnabled"
            label="Node Logs"
          />

          <el-collapse
            v-model="sessionOnDisplay"
            ref="liveCallMonitoringCollapseAccordion"
            accordion
          >
            <conversation-item
              :session="currentSession"
              :logs="logs[currentSession.session_id] || []"
              :is-call-loading="isCallLoading(currentSession.session_id)"
              :is-current="true"
              :is-paused="isPaused"
              :is-node-logs-enabled="isNodeLogsEnabled"
              @pause-live="handlePauseLive"
              @resume-live="handleResumeLive"
              @log-clicked="updateActiveNodeId"
            />
          </el-collapse>
        </div>

        <!-- Previous Conversations -->
        <div v-if="previousSessions.length">
          <h4>Previous Conversations</h4>
          <el-collapse
            v-model="sessionOnDisplay"
            ref="liveCallMonitoringCollapseAccordion"
            accordion
          >
            <conversation-item
              v-for="session in previousSessions"
              :key="session.session_id"
              :session="session"
              :logs="logs[session.session_id] || []"
              :is-call-loading="isCallLoading(session.session_id)"
              @log-clicked="updateActiveNodeId"
            />
          </el-collapse>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import ConversationItem from "./ConversationItem.vue";
import { debounce } from "throttle-debounce";

export default {
  components: {
    ConversationItem
  },
  props: {
    taskId: {
      required: true
    },
    phoneNumber: {
      required: true
    }
  },
  data() {
    return {
      isOnLive: true,
      isShown: false,
      logs: {},
      sessions: [],
      ani: "",
      rules: {
        ani: [
          {
            required: true,
            message: "Please enter a Caller Number",
            trigger: "blur"
          },
          {
            pattern: /^\d+$/,
            message: "Please enter a valid numeric value",
            trigger: "blur"
          }
        ]
      },
      activeNodeId: "1",
      selectedNodeId: null,
      liveCallTaskChannel: null,
      sessionOnDisplay: null,
      isNodeLogsEnabled: false,
      isPaused: false,
      logBuffer: [],
      debouncedScrollToBottom: null
    };
  },
  computed: {
    ...mapState("app", {
      token: state => state.token
    }),

    ...mapState("activecalls", {
      activeCalls: state => state.activeCalls
    }),

    ...mapState("conversationMonitoring", {
      isMonitoring: state => state.isMonitoring,
      isLoading: state => state.isLoading,
      error: state => state.error
    }),

    liveCallSessionChannel() {
      const normalisedSessionId = this.currentSession.session_id.replace(
        /\./g,
        "_"
      );

      return this.currentSession
        ? `live_call_monitoring.${normalisedSessionId}`
        : null;
    },

    isTaskLoading() {
      return !(this.sessions.length > 0);
    },

    currentSession() {
      if (!this.sessions.length) return null;
      if (this.sessions.length === 1) return this.sessions[0];

      return this.sessions.reduce((latest, session) =>
        latest.timestamp > session.timestamp ? latest : session
      );
    },

    previousSessions() {
      if (this.sessions.length <= 1) {
        return [];
      }

      return this.sessions
        .slice()
        .filter(
          session => session.session_id !== this.currentSession.session_id
        )
        .reverse();
    }
  },
  watch: {
    activeNodeId(newVal) {
      this.$emit("updateActiveNode", newVal);
    },
    logs() {
      if (this.isLive()) {
        this.scrollToBottom();
      }
    },

    currentSession(newVal) {
      if (newVal) {
        this.joinLiveCallSessionChannel();
        this.sessionOnDisplay = newVal.session_id;
      }
    },

    sessionOnDisplay(newVal) {
      if (
        newVal &&
        this.currentSession &&
        newVal !== this.currentSession?.session_id
      ) {
        return this.handlePauseLive();
      }

      return this.handleResumeLive();
    }
  },
  methods: {
    ...mapActions("activecalls", {
      getActiveCalls: "getActiveCalls"
    }),
    ...mapActions("conversationMonitoring", {
      enableMonitoringAction: "enableMonitoringAction",
      stopMonitoringAction: "stopMonitoringAction"
    }),

    isCallLoading(session_id) {
      return !(this.logs[session_id] && this.logs[session_id].length > 0);
    },

    handleCloseLive() {
      this.$emit("hideDetails");
    },

    getIconForNode(type) {
      return "/icons/" + type + ".svg";
    },

    joinLiveCallTaskChannel() {
      if (this.$echo && this.$echo.connector.socket.connected && this.token) {
        this.$echo.connector.options.auth.headers.Authorization =
          "Bearer " + this.token;
        this.$echo
          .join(this.liveCallTaskChannel)
          .listen("CallMonitoringTaskEvent", e => {
            if (e.call_type === "CREATE_CALL") {
              const sessionExists = this.sessions.some(
                session =>
                  String(session.session_id) ===
                  String(e.active_call_data.session_id)
              );

              if (!sessionExists) {
                this.sessions.push(e.active_call_data);
                this.$set(
                  this.logs,
                  e.active_call_data.session_id.toString(),
                  []
                );
              }

              this.currentSessionId = e.active_call_data.session_id;
            } else {
              this.logs[this.currentSessionId.toString()].push({
                event: "CALL_ENDED",
                message: "",
                log_parameters: {
                  timestamp: Date.now(),
                  session_id: e.active_call_data.session_id
                }
              });
            }
          })
          .error(error => {
            console.error(error);
          });
      }
    },

    leaveLiveCallTaskChannel() {
      this.sessions = [];
      this.logs = {};
      this.$echo.leave(this.liveCallTaskChannel);
    },

    joinLiveCallSessionChannel() {
      if (this.$echo && this.$echo.connector.socket.connected && this.token) {
        this.$echo.connector.options.auth.headers.Authorization =
          "Bearer " + this.token;
        this.$echo
          .join(this.liveCallSessionChannel)
          .listen("CallMonitoringEvent", e => {
            if (this.isPaused) {
              this.logBuffer.push(e);
            } else {
              this.appendLog(e);
              this.updateActiveNodeId(e);
            }
          })
          .error(error => {
            console.error(error);
          });
      }
    },

    leaveLiveCallSessionChannel() {
      this.$echo.leave(this.liveCallSessionChannel);
      this.activeNodeId = "1";
      this.pausedNodeId = null;
      this.currentSessionId = "";
      this.isOnLive = true;
    },

    updateActiveNodeId(e) {
      const nodeId =
        e.log_parameters?.node_id || e.log_parameters?.payload?.node_id;

      if (nodeId && (e.event === "PROCESS_NODE" || e.event === "NODE_LOG")) {
        this.activeNodeId = nodeId;
      }
    },

    async monitorCall() {
      this.$refs.callflowForm.validate(async valid => {
        if (!valid) return;

        try {
          const success = await this.enableMonitoringAction({
            taskId: this.taskId,
            ani: this.ani,
            scope: "ac"
          });

          if (success) {
            this.initializeMonitoring();
            this.joinLiveCallTaskChannel();
            await this.fetchActiveCalls();
          }
        } catch (error) {
          console.error("Error during monitoring call:", error);
        }
      });
    },

    initializeMonitoring() {
      this.isShown = true;
      this.liveCallTaskChannel = `live_call_task_monitoring.${this.taskId}.${this.ani}`;
    },

    async fetchActiveCalls() {
      await this.getActiveCalls("ac");
      this.sessions = this.activeCalls.filter(
        session =>
          String(session.task_id) === String(this.taskId) &&
          String(session.ani) === String(this.ani)
      );
      this.sessions.forEach(session => {
        this.$set(this.logs, session.session_id.toString(), []);
      });
    },

    stopMonitoring() {
      this.stopMonitoringAction();
      this.isShown = false;
      this.leaveLiveCallSessionChannel();

      this.leaveLiveCallTaskChannel();
      this.liveCallTaskChannel = null;
    },

    toggleMonitoring() {
      if (this.liveCallTaskChannel) {
        return this.stopMonitoring();
      }

      return this.monitorCall();
    },

    isLive() {
      return !this.pausedNodeId && this.isShown;
    },

    scrollToBottom() {
      if (!this.isPaused) {
        this.debouncedScrollToBottom();
      }
    },

    executeScrollToBottom() {
      const collapseAccordion = this.$refs.liveCallMonitoringCollapseAccordion;
      if (!collapseAccordion || !collapseAccordion.$children) {
        return;
      }

      collapseAccordion.$children.forEach(item => {
        const callDetail = item.$refs.callDetail;
        if (callDetail) {
          callDetail.scrollTop = callDetail.scrollHeight;
        }
      });
    },

    handlePauseLive() {
      this.isPaused = true;
    },
    handleResumeLive() {
      this.isPaused = false;

      if (this.logBuffer.length) {
        this.logBuffer.forEach(log => {
          this.updateActiveNodeId(log);
          this.appendLog(log);
        });

        this.logBuffer = [];
      }
    },

    appendLog(log) {
      log["session_id"] = this.currentSession.session_id;

      if (this.isPaused) {
        return this.logBuffer.push(log);
      }

      if (this.logs[log.session_id]) {
        return this.logs[log.session_id].push(log);
      }

      this.$set(this.logs, log.session_id.toString(), [log]);
    }
  },

  created() {
    this.debouncedScrollToBottom = debounce(500, this.executeScrollToBottom);
  },

  beforeDestroy() {
    this.stopMonitoring();
  }
};
</script>

<style lang="scss">
$content-theme-color: var(--theme-color) !default;

.live-call-monitoring {
  height: 100vh;
  max-height: 90vh;
  margin: 20px 5px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  overflow-x: hidden;
  min-width: 350px;

  .el-form-item {
    margin: 15px 10px;

    .el-form-item__label {
      font-size: 14px;
      margin-top: 3px;
    }

    .el-input--small {
      width: 225px;
    }

    .el-input__inner:focus {
      border-color: $content-theme-color;
    }

    .el-button--primary {
      background-color: $content-theme-color;
      border-color: $content-theme-color;
    }
  }

  .el-select.el-select--small {
    width: 90%;

    .el-tag.el-tag--info.el-tag--mini.el-tag--light {
      height: 26px;
    }

    .el-select__tags-text {
      font-size: 13px;
    }
  }

  .el-collapse {
    border: 1px solid #ebeef5;
  }

  .el-collapse-item__header {
    position: relative;
    height: auto;
    line-height: 35px;
    display: block;
    padding: 10px;

    .select-node-detail {
      margin-top: 5px;
      width: 200px;
    }

    .timestamp {
      color: #a0a8b5;
      height: 30px;
    }

    .phone {
      color: #181f29;
      height: 30px;
    }

    .switch {
      position: absolute;
      top: 20px;
      right: 25px;

      .el-switch__label {
        position: absolute;
        display: none;
        color: #fff !important;
      }

      //turn on switch
      .el-switch__label--right {
        z-index: 1;
        right: -12px;

        span {
          font-size: 13px;
        }
      }

      .el-switch__label--left {
        z-index: 1;
        left: 20px;

        span {
          font-size: 13px;
        }
      }

      .el-switch__label.is-active {
        display: block;
        color: $content-theme-color;
      }

      &.el-switch .el-switch__core,
      &.el-switch .el-switch__label {
        width: 75px !important;
      }

      &.el-switch.is-checked .el-switch__core {
        border-color: $content-theme-color;
        background-color: $content-theme-color;
      }
    }
  }

  .el-collapse-item__header .el-collapse-item__content {
    padding-bottom: 0;
  }

  .node-event:last-child {
    .log-event {
      -webkit-animation-duration: 1s;
      -webkit-animation-name: changeColor;
      -webkit-animation-iteration-count: 1;
      animation-duration: 1s;
      animation-name: changeColor;
      animation-iteration-count: 1;
    }
  }

  @-webkit-keyframes changeColor {
    0% {
      color: $content-theme-color;
    }
    90% {
      color: $content-theme-color;
    }
    100% {
      color: #181f29;
    }
  }

  @keyframes changeColor {
    0% {
      color: $content-theme-color;
    }
    95% {
      color: $content-theme-color;
    }
    100% {
      color: #181f29;
    }
  }

  .node-name {
    display: flex;
    align-items: center;

    .node-icon {
      margin-right: 5px;
      width: 24px;
      height: 24px;
    }
  }
}

.svg-icon {
  path {
    fill: #454545;
  }
}

#node-log-checkbox {
  right: 0;
  padding: 0 10px 10px 0;
  display: flex;
  justify-content: flex-end;
}
</style>
